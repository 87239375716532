export const faq = [
    {
        title: "1. What information do we use?",
        description: "An example of the type of information that we use would be your name, address, telephone number, date of birth etc. For further information on the information we collect, please visit our [Privacy Policy](/privacy-policy)."
    },
    {
        title: "2. Why do we use your information:",
        description: "We collect your personal information in order to provide the most efficient products and services; whether this be for an insurance policy or a claim."
    },
    {
        title: "3. How do we use your information?",
        description: "We use your information to validate claims and policies and also to identity who you are."
    },
    {
        title: "4. When do you use my information?",
        description: "We only use your information when instructed to do so by you. This may be when taking out a policy or making a claim."
    },
    {
        title: "5. How long to you keep my information for?",
        description: "We only retain your data for as long as necessary which is for a maximum period of 6 years. However, we also do not wish to hold information unnecessarily, and therefore we will review what information we hold as a company annually."
    },
    {
        title: "6. Is my information secure?",
        description: "Yes. All of our processes and procedures ensure that your information is as secure as possible. We carry out regular audits and reviews to ensure that we keep up to date with current Information Security technology."
    },
    {
        title: "7. What is a Subject Access Request (SAR)?",
        description: "A Subject Access Request is a request by you to obtain the information a company may hold about you."
    },
    {
        title: "8. How do I make a Subject Access Request?",
        description: "You can request this via an email or in writing, please follow this [link](/full-privacy-policy) for more information. (see GDPR rights)"
    },
    {
        title: "9. What are my Rights?",
        description: [
            {
                text: 'Under the GDPR regulations your rights are as follows:'
            },
            {
                text: '＞ The right to be informed'
            },
            {
                text: '＞ The right of access'
            },
            {
                text: '＞ The right to rectification'
            },
            {
                text: '＞ The right to erasure'
            },
            {
                text: '＞ The right to restrict processing'
            },
            {
                text: '＞ The right to data portability'
            },
            {
                text: '＞ The right to object'
            },
            {
                text: 'Rights in relation to automated decision making and profiling.'
            },
        ]
    },
    {
        title: "10. How do I contact you regarding Privacy?",
        description: [
            {
                text: 'You can contact us via telephone, email or in writing.'
            },
            {
                text: '[Please see our contact us page.](/contact-us)'
            },
            {
                text: 'Our full Privacy Policy can be found [here](/full-privacy-policy).'
            },
        ]
    },
]